import { each }               from 'lodash'
import { gsap }               from "gsap/dist/gsap"
import { SplitText }          from "gsap/dist/SplitText"

gsap.registerPlugin(SplitText)

import {x, x0} from '../_helpers'

export default class SplitLines {

    constructor() {
        this.split = null
        this.elements = x('[js-split]')
        if (this.elements)  this.init()
    }

    reset() {
        this.split.revert()
        this.split = new SplitText(this.elements, {type:"words,lines"})
    }

    init() {
        this.split = new SplitText(this.elements, {type:"words,lines"})

        each(this.split.words, el => {
            el.setAttribute('js-reveal','fade-in')
        })

        window.addEventListener('resize', e => {
            this.reset()
        })
    }
}