import {each} from 'lodash'
import {captureException, x, x0} from '../_helpers'
import {gsap} from "gsap/dist/gsap"
import {ScrollTrigger} from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)


export default class Tool {

    constructor() {
        this.tool = x0('.m-tool')

        if (this.tool) {
            this.triggers = x('[js-tool-qid]')
            this.close_triggers = x('[js-tool-close]')
            this.feedback_triggers = x('[js-tip-feedback]')

            const tokenMeta = document.querySelector('meta[name="csrf-token"]')
            this.token = tokenMeta ? tokenMeta.content : null

            this.init()
        }
    }

    show(el) {
        this.tool.classList.add('-active')
        this.tool.setAttribute('js-question', el.getAttribute('js-tool-qid'))
        x('.m-tool li')[el.getAttribute('js-tool-qid')-1].classList.add('-show')
    }

    hide(el) {
        this.tool.classList.remove('-active')
        this.tool.setAttribute('js-question', false)
        x('.m-tool li')[el.getAttribute('js-tool-qid')-1].classList.remove('-show')
    }

    handleEvents() {
        each(this.triggers, el => {
            gsap.to('.m-tool', {
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom",
                    end: "bottom bottom",

                    onEnter: () => {
                        this.show(el)
                    },
                    onEnterBack: () => {
                        this.show(el)
                    },
                    onLeave: () => {
                        this.hide(el)
                    },
                    onLeaveBack: () => {
                        this.hide(el)
                    }
                }
            })
        })

        each(this.close_triggers, el => {
            el.addEventListener('click', el => {
                this.tool.classList.remove('-carousel-open')
                each(x('.m-tool__carousel'), el => {
                    el.classList.remove('-show')
                })
            })
        })

        each(this.feedback_triggers, feedback_trigger => {
            const buttons = x('[js-tip-feedback-trigger]', feedback_trigger)
            const answers = x0('.m-tool__card-feedback__answers', feedback_trigger)
            const message = x0('.m-tool__card-feedback__done', feedback_trigger)

            each(buttons, button => {
                button.addEventListener('click', e => {
                    e.preventDefault()

                    if (!this.token) {
                        console.error('Missing CSRF token.')
                        return
                    }

                    answers.classList.add('-hidden')
                    message.classList.remove('-hidden')

                    fetch(button.dataset.href, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-TOKEN': this.token,
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache'
                        }
                    }).then(response => response.json())
                        .then(data => {
                            if (data.success && data.points) {
                                const label = data.points > 1 ? 'punti' : 'punto'
                                window.paneangeli.tooltips.push(
                                    `Complimenti,<br><strong>Hai guadagnato ${data.points} ${label}!</strong>`,
                                    'qubi'
                                )
                            }
                        }).catch((err) => {
                        answers.classList.remove('-hidden')
                        message.classList.add('-hidden')

                        console.error('Could not send feedback.')
                        captureException(err)
                    })
                })
            })
        })

        x0('.m-tool__baloon').addEventListener('click', el => {
            this.tool.classList.add('-carousel-open')

            x('.m-tool__carousel')[this.tool.getAttribute('js-question')-1].classList.add('-show')
        })
    }

    init() {
        this.handleEvents()
    }

}
