import { each }  from 'lodash'
import { x, x0, isPlaying } from '../_helpers'
import { gsap }  from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)


export default class Video {

    constructor() {
        this.elements = x('video')
        if (!x0('.m-media-hero.-landing')) {
            this.init()
        }
    }


    handleEvents() {
        each(this.elements, el => {
            gsap.to(el, {
                scrollTrigger: {
                    trigger: el,
                    start: "top bottom",
                    end: "bottom top",
                    onEnter: () => {
                        el.play()
                    },
                    onEnterBack: () => {
                        el.play()
                    },
                    onLeave: () => {
                        if (isPlaying(el)) {
                            el.pause()
                        }
                    },
                    onLeaveBack: () => {
                        if (isPlaying(el)) {
                            el.pause()
                        }
                    }
                }
            })
        })
    }

    init() {
        this.handleEvents()
    }

}
