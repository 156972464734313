import { x, x0 } from '../_helpers'
import { gsap }  from "gsap/dist/gsap"


export default class Magnet {

    constructor() {
        this.magnetElements = x('[js-magnet]')
        if (this.magnetElements) this.init()
    }

    handleMagnet() {
        [].forEach.call(this.magnetElements, (el) => {
            el.addEventListener('mousemove', (e) => {
                this.moveMagnet(e, el)
            })

            el.addEventListener('mouseleave', () => {
                this.resetMagnet(el)
            })
        })
    }

    resetMagnet(el) {
        gsap.to(el, {
            duration: 1,
            x: '0%',
            y: '0%',
            ease: "elastic.out(1, 0.3)"
        })
    }

    moveMagnet(e, el) {
        const coords = el.getBoundingClientRect()
        const coord = {
          'x': coords.left,
          'y': coords.top
        }

        gsap.to(el, {
            duration: .5,
            x: 2 * ((e.clientX - coord.x) / (el.offsetWidth) * 2 - 1 ) + '%',
            y: 2 * ((e.clientY - coord.y) / (el.offsetHeight) * 2 - 1 ) + '%'
        })
    }

    init() {
        this.handleMagnet()
    }

}
