import {gsap} from "gsap/dist/gsap"
import {each} from 'lodash'
import {x} from '../_helpers'


export default class Fadeoutsection {

    constructor() {
        this.triggers = x('[js-fadeoutsection]')

        if (this.triggers.length) {
            this.init()
        }
    }

    init() {
        each(this.triggers, (el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();
                gsap.to(el.closest('[js-fadeoutsection-wrapper]'), {
                    opacity: 0,
                    y: '20',
                    display: 'none'
                })

                const cookie_name = el.getAttribute('js-fadeoutsection-persist')
                if (cookie_name) {
                    var d = new Date;
                    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 7); //7 days
                    document.cookie = `${cookie_name}=1;path=/;expires=${d.toGMTString()}`;
                }
            })
        })

    }


}
