import { each }  from 'lodash'
import { x, x0 } from '../_helpers'
import { gsap }  from "gsap/dist/gsap"
import Headspace from 'headspace'


export default class Menu {

    constructor() {
        this.menuTrigger = x('[js-menu-trigger]')
        this.triggers    = x('[js-subnav-trigger]')
        this.backs       = x('[js-subnav-back]')
        this.close       = x0('[js-subnav-close]')
        this.isOpen      = false
        this.init()
    }

    reset() {
        each(x('.m-menu__nav'), el => {
            el.classList.remove('-active')
            el.classList.remove('-prev-status')
        })

        each(this.triggers, (el, i) => {
            el.classList.remove('-active')
        })
    }

    handleEvents() {
        each(this.triggers, (el, i) => {
            el.addEventListener('click', () => {
                this.reset()
                el.classList.add('-active')
                
                let destination = el.getAttribute('js-subnav-trigger')
                el.parentElement.parentElement.parentElement.classList.add('-prev-status')
                x0(`[js-subnav="${destination}"]`).classList.add('-active')

                if (window.innerWidth > 1024) {
                    x0('body').classList.add('menu-is-open')
                }
            })
        })

        each(this.backs, (el, i) => {
            el.addEventListener('click', () => {
                el.parentElement.classList.remove('-active')
                el.parentElement.classList.remove('-prev-status')
            })
        })

        each(this.menuTrigger, el => {
            el.addEventListener('click', () => {
                this.reset()
                x0('body').classList.toggle('menu-is-open')
            })
        })

        this.close.addEventListener('click', () => {
            x0('body').classList.remove('menu-is-open')
            this.reset()
        })
    }

    init() {
        this.handleEvents()

        Headspace(x0('.m-header'), {
            startOffset: 90,
            tolerance: 5,
            showAtBottom: false,
            classNames: {
                base: 'm-header',
                fixed: '-fixed',
                hidden: '-hidden'
            }
        })
    }

}