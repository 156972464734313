import {each}  from 'lodash'
import {x, x0} from '../_helpers'
import StickySidebar from 'sticky-sidebar'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
    
export default class Sidebar {
    constructor() {
        this.element = x0('[js-sticky-sidebar]')

        if (this.element) {
            this.init()
        }
    }

    handleStatus(i) {
        if (x0('[js-sticky-sidebar] li.-active')) {
            x0('[js-sticky-sidebar] li.-active').classList.remove('-active')
        }
        x(`[js-sticky-sidebar] li`)[i].classList.add('-active')
    }

    handleActive() {
        each(
            x('[js-sticky-sidebar-section]'), (el, i) => {
                ScrollTrigger.create({
                    trigger: el,
                    start: `top bottom`,
                    onEnter: () => {
                        this.handleStatus(i)
                    },
                    onEnterBack: () => {
                        this.handleStatus(i)
                    }
                });
            }
        );
    }

    init() {
        const stickySidebar = new StickySidebar('[js-sticky-sidebar]', {
            topSpacing: window.innerWidth/14
        })
        
        this.handleActive()

        setInterval(() => {
            ScrollTrigger.refresh()
            stickySidebar.updateSticky()
        }, 100)
    }
}