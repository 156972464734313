import {each} from 'lodash'
import {loadImage, x, x0} from '../_helpers'
import {gsap} from "gsap/dist/gsap"

export default class TooltipStack {

    constructor() {
        this.duration = 3000

        this.stack = x0('.m-tooltip__stack')
        this.template = x0('.m-tooltip__template').innerHTML

        this.triggers = x('[js-tooltip-trigger]')
        if (this.triggers.length) {
            this.initTriggers()
        }

        this.activeTooltips = x('.m-tooltip.-active')
        if (this.activeTooltips.length) {
            this.showActiveTooltips()
        }
    }

    initTriggers() {
        each(this.triggers, (el, i) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                const tooltip = x0(`[js-tooltip-id="${el.getAttribute('js-tooltip-trigger')}"]`)

                if (tooltip) {
                    el.classList.add('-disabled');

                    this.enable(tooltip, 0, () => {
                        el.classList.remove('-disabled')
                    })
                }
            })
        })
    }

    enable(tooltip, i = 0, onHidden = null) {
        setTimeout(() => {
            this.show(tooltip)

            if (!tooltip.classList.contains('-sticky')) {
                setTimeout(() => {
                    this.hide(tooltip)

                    if (onHidden !== null) {
                        onHidden()
                    }
                }, this.duration)
            }
        }, 1000 * i)
    }

    show(tooltip) {
        gsap.fromTo(
            tooltip,
            {opacity: 0, y: 40, display: 'none'},
            {opacity: 1, y: 0, display: 'block'}
        )
    }

    hide(tooltip) {
        gsap.fromTo(
            tooltip,
            {opacity: 1, y: 0, display: 'block'},
            {
                opacity: 0, y: 40, display: 'none', onComplete: () => {
                    //tooltip.remove()
                }
            },
        )
    }

    showActiveTooltips() {
        each(this.activeTooltips, (tooltip, i) => {
            this.enable(tooltip, i)
        })
    }

    push(text, icon, alt = '', onHidden = null) {
        loadImage('/assets/img/icons/' + icon + '.svg')
            .then(image => {
                let tooltip = this.template
                    .replace(':text', text)
                    .replace(':icon', icon)
                    .replace('data-src=', 'src=')

                this.stack.innerHTML += tooltip

                this.enable(x0('.m-tooltip:last-child', this.stack), 0, onHidden)
            })
    }
}
