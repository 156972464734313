import { each } from 'lodash'
import { x } from '../_helpers'


export default class Print {

    constructor() {
        
        this.init()
    }

    handleEvents() {
        this.triggers = x('[js-print]')

        window.addEventListener('beforeprint', () => {
            each(x('.lazy'), el => {
                el.setAttribute('src', el.getAttribute('data-src'))
            })
        })

        if (this.triggers) {
            each(this.triggers, el => {
                el.addEventListener('click', e => {
                    e.preventDefault();
                    each(x('.lazy'), el => {
                        el.setAttribute('src', el.getAttribute('data-src'))
                    })
                    setTimeout(() => {
                        window.print()
                    }, 100)
                })
            })
        }
    }

    init() {
        this.handleEvents()
    }

}