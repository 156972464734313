import {gsap} from "gsap/dist/gsap"
import {each} from 'lodash'
import {x, loadImage, captureException} from '../_helpers'

export default class Favourite {

    constructor() {
        this.triggers = x('[js-favourite-trigger]')

        if (this.triggers.length) {
            this.init()
        }
    }

    init() {
        const tokenMeta = document.querySelector('meta[name="csrf-token"]')
        this.token = tokenMeta ? tokenMeta.content : null

        const endpointMeta = document.querySelector('meta[name="favourites-endpoint"]')
        this.endpoint = endpointMeta ? endpointMeta.content : null
        if (this.endpoint) {
            this.loadUserData()
        }

        loadImage('/assets/img/icons/heart.svg')

        this.handleEvents()
    }

    loadUserData() {
        this.userFavourites = []

        fetch(this.endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache'
            }
        })
            .then(response => response.json())
            .then(data => {
                this.userFavourites = data

                this.initTriggerStatus()
            })
            .catch(e => {})
    }

    initTriggerStatus() {
        each(this.userFavourites, (items, namespace) => {
            each(items, id => {
                const triggers = x(`[js-favourite-trigger][data-favourite-namespace="${namespace}"][data-favourite-id="${id}"]`)

                if (triggers.length) {
                    each(triggers, el => {
                        el.classList.add('-active');
                    })
                }
            })
        })
    }

    handleEvents() {
        each(this.triggers, (el) => {
            el.addEventListener('click', (e) => {
                e.preventDefault();

                this.toggleElement(el)
            })
        })
    }

    toggleElement(el) {
        const namespace = el.dataset.favouriteNamespace === 'shopping_list' ? 'shopping-list' : 'favourite'

        if (el.classList.contains('-active')) {
            this.deactivateElement(el, namespace)
        } else {
            this.activateElement(el, namespace)

            this.handleTracking(el)
        }
    }

    deactivateElement(el, namespace) {
        el.classList.remove('-active')
        el.setAttribute('js-tooltip-trigger', `not-${namespace}`)

        /*window.paneangeli.tooltips.push(
            namespace === 'shopping-list' ? 'Rimosso dalla lista della spesa' : 'Rimosso dai preferiti',
            'heart'
        )*/

        if (el.hasAttribute('js-fadeout')) {
            gsap.to(el.closest('[js-fadeout-wrapper]'), {
                opacity: 0,
                y: '20',
                display: 'none'
            })
        }

        if (el.dataset.favouriteRemoveUrl && this.token) {
            fetch(el.dataset.favouriteRemoveUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': this.token
                }
            }).then(response => {
                if (response.status !== 200) {
                    el.classList.add('-active');
                }
            }).catch((err) => {
                el.classList.add('-active')
                captureException(err)
            })
        }
    }

    activateElement(el, namespace) {
        el.classList.add('-active')
        el.setAttribute('js-tooltip-trigger', namespace)

        /*window.paneangeli.tooltips.push(
            namespace === 'shopping-list' ? 'Aggiunto alla lista della spesa' : 'Aggiunto ai preferiti',
            'heart'
        )*/

        if (el.dataset.favouriteAddUrl && this.token) {
            fetch(el.dataset.favouriteAddUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': this.token
                }
            }).then(response => {
                if (response.status !== 200) {
                    el.classList.remove('-active');
                }
            }).catch((err) => {
                el.classList.remove('-active')
                captureException(err)
            })
        }
    }

    handleTracking(el) {
        let tracking = el.getAttribute('js-favourite-track-event')
        if (tracking) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(JSON.parse(tracking).data)
        }
    }
}
