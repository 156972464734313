import {each} from 'lodash'
import {x, x0, reloadWithCacheBusting, getCookie} from '../_helpers'
import tingle from 'tingle.js'
import Tracking from "./tracking"
import ReviewForm from './review-form'

export default class Modal {

    constructor(triggers = null, context = document, overlay = null) {
        this.triggers = triggers !== null ? triggers : x('[js-modal-trigger]', context)
        this.overlay = overlay !== null ? overlay : x0('[js-modal-id="modal-overlay"]', context)

        if (this.triggers.length) {
            this.init()
        }

        if (this.overlay) {
            this.initOverlay();
        }
    }

    init() {
        each(this.triggers, el => {
            this.handleEvents(el)
        })
    }

    initOverlay() {
        if (!getCookie("paneangeli_overlay")) {
            this.openModal(this.overlay);
        }
    }

    handleEvents(el) {
        el.addEventListener('click', e => {
            e.preventDefault()

            const contentEl = x0(el.getAttribute('href'))

            if (contentEl) {
                this.openModal(contentEl)
            }
        })
    }

    openModal(contentEl) {
        const modal = this.createModal(contentEl.innerHTML)

        this.setupListeners(modal, contentEl)

        modal.open()
    }

    createModal(content) {
        const modal = new tingle.modal({
            closeMethods: ['overlay', 'button', 'escape'],
            onOpen: function () {
                new Tracking(x('[js-track-event]', this.modalBoxContent))

                const closeTriggers = x('[js-modal-close]', this.modalBoxContent)
                if (closeTriggers.length) {
                    each(closeTriggers, el => {
                        el.addEventListener('click', function () {
                            modal.close()
                        })
                    })
                }
            },
            onClose: function () {
                if (this.modalBoxContent.dataset.reloadWithoutCacheAfterClose) {
                    reloadWithCacheBusting()
                }

                const overlay = x0('.m-box-overlay', this.modalBoxContent)
                if (overlay && !getCookie('paneangeli_overlay')) {
                    const d = new Date;
                    d.setTime(d.getTime() + overlay.dataset.cookieDuration * 60 * 60 * 1000);
                    document.cookie = `paneangeli_overlay=1;path=/;expires=${d.toUTCString()}`;
                }
            }
        })

        modal.setContent(content)

        return modal
    }

    setupListeners(modal, contentEl) {
        const form = contentEl.closest('form')

        new ReviewForm(modal.modalBoxContent, modal)

        if (form) {
            //We're dealing with a form confirm modal
            const submit = x0('button[type=submit]', modal.modalBoxContent)
            if (submit) {
                submit.addEventListener('click', () => {
                    form.modalIndex++
                    form.requestSubmit()
                    modal.close()
                })
            }

            const cancel = x0('[js-cancel-button]', modal.modalBoxContent)
            if (cancel) {
                cancel.addEventListener('click', () => {
                    form.modalIndex = 0
                })
            }
        }

        const ctas = x('[js-modal-close-onclick]', modal.modalBoxContent)
        each(ctas, el => {
            el.addEventListener('click', () => {
                modal.close()
            })
        })
    }
}
