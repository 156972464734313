import {each, filter, keyBy} from 'lodash'
import {x, x0} from '../_helpers'
import moment from 'moment'


export default class AddressForm {
    constructor() {
        this.triggers = x('[js-address-form]')
        if (this.triggers.length) {
            this.init()
        }
    }

    init() {
        each(this.triggers, el => {
            this.setupConfirmModals(el)

            this.setupAddressCopy(el)
            this.setupBirthDateChangeListener(el)
            this.setupAddressChangeListener(el)
            this.setupAutocomplete(el)
        })
    }

    setupConfirmModals(el) {
        el.modalIndex = 0

        el.addEventListener('submit', e => {
            const modalTriggers = x('[js-form-modal-trigger]', el)

            if (modalTriggers.length) {
                const triggers = filter(modalTriggers, trigger => {
                    const condition = trigger.getAttribute('js-modal-condition')

                    return (!condition || el.classList.contains(condition))
                })

                if (triggers.length && (el.modalIndex < triggers.length)) {
                    e.preventDefault()
                    triggers[el.modalIndex].click()
                }
            }
        })
    }

    setupAddressCopy(el) {
        const addressCopy = x0('[js-address-copy-trigger]', el)
        const shippingAddress = x0('#shipping-address', el)

        if (addressCopy && shippingAddress) {
            addressCopy.addEventListener('change', e => {
                shippingAddress.style.display = e.target.checked ? 'none' : 'block';

                if (e.target.checked) {
                    this.copyMailingAddressToShippingAddress()
                    this.markShippingAddressAsChanged(el)
                }
            })
        }
    }

    setupBirthDateChangeListener(el) {
        const birthDate = x0('#birth_date', el)

        if (birthDate) {
            el.dataset.oldBirthDate = birthDate.value

            birthDate.addEventListener('change', () => {
                this.onBirthDateChanged(el)
            })
        }
    }

    onBirthDateChanged(el) {
        const birthDate = x0('#birth_date', el)

        const oldAge = this.dateToAge(el.dataset.oldBirthDate)
        const newAge = this.dateToAge(birthDate.value)

        el.classList.remove('birth-date-18-down')
        el.classList.remove('birth-date-16-down')
        el.classList.remove('birth-date-18-up')

        if (oldAge !== newAge) {
            if (oldAge >= 18 && newAge < 18) {
                el.classList.add('birth-date-18-down')
            } else if (oldAge >= 16 && newAge < 16) {
                el.classList.add('birth-date-16-down')
            } else if (oldAge < 18 && newAge >= 18) {
                el.classList.add('birth-date-18-up')
            }
        }
    }

    dateToAge(date) {
        return moment().diff(date, 'years')
    }

    setupAddressChangeListener(el) {
        const shippingAddress = x0('#shipping-address', el)

        if (!shippingAddress) {
            return
        }

        const fields = x('input, select', shippingAddress)

        if (fields.length) {
            each(fields, field => {
                field.addEventListener('change', () => {
                    this.markShippingAddressAsChanged(el)
                })
            })
        }
    }

    markShippingAddressAsChanged(el) {
        if (!el.classList.contains('shipping-changed')) {
            el.classList.add('shipping-changed')
        }
    }

    copyMailingAddressToShippingAddress() {
        const fieldMap = {
            shipping_street: 'address',
            shipping_city: 'city',
            shipping_province: 'province',
            shipping_zipcode: 'zipcode',
            shipping_country: 'country'
        }
        each(fieldMap, (source, target) => {
            const targetField = document.getElementById(target)
            const sourceField = document.getElementById(source)

            targetField.value = sourceField.value
            targetField.disabled = sourceField.disabled
        })
    }

    setupAutocomplete(container) {
        const els = x('[js-address-autocomplete]', container)

        each(els, el => {
            let typing = false
            const fieldMap = JSON.parse(el.getAttribute('js-address-autocomplete'))

            /*if (!window.google) {
                console.error('google.maps.places not found')
                return
            }*/

            /*const autocomplete = new window.google.maps.places.Autocomplete(el, {
                componentRestrictions: {country: ['it', 'sm']},
                fields: ["address_components"],
                types: ["address"],
            });*/

            el.addEventListener('keydown', e => {
                if (e.keyCode === 13) {
                    if (typing) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                } else {
                    // means the user is probably typing
                    typing = true;
                }
            })

            el.addEventListener('autocomplete_place_changed', (event) => {
                this.onPlaceChanged(el, event.detail.autocomplete, fieldMap)
                typing = false

                if (el.name === 'shipping_street') {
                    this.markShippingAddressAsChanged(container)
                }
            });
        })
    }

    onPlaceChanged(el, autocomplete, fields) {
        const place = autocomplete.getPlace()

        if (typeof place.address_components === 'undefined') {
            return
        }

        const components = keyBy(place.address_components, component => component.types[0])

        if (typeof components?.street_number?.long_name === 'undefined') {
            this.resetFields(fields)
            this.showStreetNumberError(el.parentNode)
            return
        }

        this.hideStreetNumberError(el.parentNode)

        const address = {
            street: components?.route?.long_name ?? '',
            street_number: components?.street_number?.long_name ?? '',
            zipcode: components?.postal_code?.long_name ?? '',
            city: (components?.administrative_area_level_3?.long_name ?? components?.locality?.long_name) ?? '',
            province: components?.administrative_area_level_2?.short_name ?? '',
            country: components?.country?.long_name ?? ''
        }

        el.value = address.street + (address.street_number ? `, ${address.street_number}` : '')
        x0(fields.zipcode).value = address.zipcode
        x0(fields.city).value = address.city
        x0(fields.province).value = address.province
        x0(fields.country).value = address.country

        //x0(fields.province).disabled = (components?.country?.short_name === 'SM')
    }

    resetFields(fields) {
        x0(fields.zipcode).value = ''
        x0(fields.city).value = ''
        x0(fields.province).value = ''
        x0(fields.country).value = ''
    }

    hideStreetNumberError(container) {
        container.classList.remove('-error')
        container.querySelector('.-street-number-error').classList.add('-hidden')
    }

    showStreetNumberError(container) {
        container.classList.add('-error')
        container.querySelector('.-street-number-error').classList.remove('-hidden')
    }
}

