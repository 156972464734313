import {debounce, each} from 'lodash'
import autoComplete from "@tarekraafat/autocomplete.js"
import {x, x0} from '../_helpers'


export default class Filters {

    constructor() {
        this.filters = x0('.m-filters')
        this.filtersChanged = false
        this.notFound = []

        if (this.filters) {
            this.selectedFilters = []
            this.triggers = x('[js-filters-trigger]')
            this.init()
        }
    }

    actions(el) {
        switch (el.getAttribute('js-filters-trigger')) {
            case "open":
                x0('body').classList.add('-filters-open')
                break;

            case "close":
                x0('body').classList.remove('-filters-open')
                if (this.filtersChanged) {
                    this.filters.submit()
                }
                break;

            case "reset":
                this.resetFilters()
                this.filtersChanged = true
                break;

            default:
        }

        each(x('.m-filters input'), el => {
            el.addEventListener('change', () => {
                this.filtersChanged = true
            })
        })

    }

    resetFilters() {
        this.updateSelectedFilters(false)
        each(x('.m-filters .m-custom-radio input'), el => {
            el.checked = false
        })
    }

    handleEvents() {
        each(this.triggers, el => {
            el.addEventListener('click', () => {
                this.actions(el)
            })
        })

        const _boxFilters = document.querySelector('.m-list-autocomplete')

        _boxFilters.addEventListener('click', (evt) => {
            if (evt.target.classList.contains('m-active-filter')) {
                let removeIndex = this.selectedFilters.map(function (item) {
                    return item.id;
                }).indexOf(evt.target.getAttribute('data-product'));
                this.selectedFilters.splice(removeIndex, 1);
                evt.target.parentElement.removeChild(evt.target)
            }
        })
    }

    initAutoComplete() {
        this.element = x0('[js-input-autocomplete]');
        const _data = this.element.getAttribute('js-input-autocomplete');

        new autoComplete({
            data: {
                src: JSON.parse(_data),
                keys: ["label"]
            },
            sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
                if (a.match < b.match) return -1;
                if (a.match > b.match) return 1;
                return 0;
            },
            selector: "[js-input-autocomplete]",           // Input field selector              | (Optional)
            observer: true,                      // Input field observer | (Optional)
            threshold: 1,                        // Min. Chars length to start Engine | (Optional)
            searchEngine: "strict",              // Search Engine type/mode           | (Optional)
            maxResults: 5,                         // Max. number of rendered results | (Optional)
            highlight: true,                       // Highlight matching results      | (Optional)
            resultItem: {
                tag: "li",
                class: "autoComplete_result",
                element: (item, data) => {
                    // Modify Results Item Content
                    item.innerHTML = `
                        <div data-product='${data.value.id}'>
                            <div><img src="${data.value.image}"></div>
                            <span>${data.match}</span>
                        </div>
                        `;
                },
                highlight: "autoComplete_highlight",
                selected: "autoComplete_selected"
            },
            resultsList: {
                element: debounce((list, data) => {
                    if (!data.results.length) {
                        this.trackNotFound(data.query)
                    }
                }, 1000),
                noResults: true,
            },
            events: {
                input: {
                    selection: (event) => {
                        this.element.value = '';
                        this.updateSelectedFilters(event)
                    },
                },
            },
        })
    }

    trackNotFound(query) {
        if (!this.notFound.includes(query)) {
            this.notFound.push(query)

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'event': 'asyncEvent',
                'eventCategory': 'ricerca filtri',
                'eventAction': 'prodotto non trovato',
                'eventLabel': query,
                'nonInteraction': 'false'
            })
        }
    }

    updateSelectedFilters(product) {
        if (product) {
            if (this.selectedFilters.indexOf(product.detail.selection.value) > -1) {
            } else {
                this.selectedFilters.push(product.detail.selection.value)
                x0('.m-list-autocomplete').innerHTML += `
                    <div class="m-active-filter">${product.detail.selection.value.label}<input type="hidden" name="filters[product][]" value="${product.detail.selection.value.id}"><i class="m-icon"><svg viewBox="0 0 12 11"><path d="M9.50257 2.81599L11.6372 0.125978L8.76723 2.12681C6.13451 3.9498 5.82617 3.9498 3.19343 2.12681L0.323486 0.125977L2.45815 2.81599C4.40307 5.28369 4.40307 5.5727 2.45815 8.04039L0.323488 10.7304L3.19342 8.72957C5.82617 6.90661 6.13451 6.90661 8.76723 8.72957L11.6372 10.7304L9.50257 8.04039C7.55763 5.5727 7.55763 5.28368 9.50257 2.81599Z"></path></svg></i></div>`
            }
        } else {
            this.selectedFilters = []
            x0('.m-list-autocomplete').innerHTML = ''
        }
    }

    init() {
        this.handleEvents()
        this.initAutoComplete()
    }
}
