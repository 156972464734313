import {x} from '../_helpers'
import {each} from 'lodash'

export default class MapsAutocomplete {
    constructor() {
        this.triggers = x('[js-address-autocomplete]')
        if (this.triggers.length) {
            this.init()
        }
    }

    init() {
        each(this.triggers, el => {
            if (!window.google || !window.google.maps) {
                console.error('google.maps.places not found')
                return
            }

            const autocomplete = new window.google.maps.places.Autocomplete(el, {
                componentRestrictions: {country: ['it', 'sm']},
                fields: ['address_components'],
                types: ['address'],
            })

            autocomplete.addListener('place_changed', () => {
                el.dispatchEvent(new CustomEvent('autocomplete_place_changed', {detail: {autocomplete}}))
            })
        })
    }
}
