import {x} from "../_helpers";
import {each} from "lodash";


export default class ClickOnce {

    constructor() {
        this.triggers = x('[js-click-once]')
        this.forms = x('[js-submit-once]')
        if (this.triggers.length || this.forms.length) {
            this.init()
        }
    }

    init() {
        each(this.triggers, el => {
            el.addEventListener('click', () => {
                this.disableButton(el)
            })
        })

        each(this.forms, form => {
            const buttons = x('button[type="submit"], [js-submit-once-target]', form)
            if(buttons.length) {
                form.addEventListener('submit', () => {
                    each(buttons, this.disableButton)
                })
            }
        })
    }

    disableButton(el) {
        el.classList.add('-disabled')
        window.setTimeout(() => {
            el.disabled = true
        }, 10)
    }
}
