import { each }           from 'lodash'
import { x, x0 }          from '../_helpers'
import { gsap }           from "gsap/dist/gsap"
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin)


export default class ScrollTo {

    constructor() {
        this.triggers = x('[js-scrollto]')
        if (this.triggers) {
            this.init()
        }
    }

    handleEvents() {
        each(this.triggers, el => {
            el.addEventListener('click', e => {
                e.preventDefault()
                gsap.to(window, {
                    duration: 1,
                    scrollTo: el.getAttribute('href')
                })
            })
        })
    }

    init() {
        this.handleEvents()
    }

}