import {x, x0} from "../_helpers";
import {each} from "lodash";

export default class LoadMore {
    constructor() {
        this.els = x('[js-load-more]')
        if (this.els.length) {
            this.init()
        }
    }

    init() {
        each(this.els, el => {
            this.setupTriggers(el)
        })
    }

    setupTriggers(el) {
        const triggers = x('[js-load-more-trigger]', el)
        const container = x0('[js-load-more-container]', el)

        if (triggers.length) {
            each(triggers, trigger => {
                trigger.addEventListener('click', e => {
                    e.preventDefault()

                    trigger.setAttribute('disabled', '')

                    fetch(trigger.href, {
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-Type': 'application/json',
                            'Cache-Control': 'no-cache',
                            'Pragma': 'no-cache'
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (container) {
                                container.innerHTML = container.innerHTML + data.html
                            }
                            trigger.href = data.next_page_url
                            if (!data.has_more) {
                                trigger.style.display = 'none'
                            } else {
                                trigger.removeAttribute('disabled')
                            }
                        })
                })
            })
        }
    }
}
