import {each} from 'lodash';
import {x, x0} from '../_helpers';
import ClipboardJS from 'clipboard';

export default class Share {

    constructor() {
        this.triggersBaloon = x('[js-share]');
        this.triggers = x('[js-share-icon]');
        if (this.triggers) {
            this.init();
        }
    }

    _openPopup(url, width, height) {
        if (!url) return;
        width = width ? width : 600;
        height = height ? height : 600;
        let pos = {
            left: screen.width / 2 - width / 2,
            top: screen.height / 2 - height / 2
        };
        window.open(url, "Share", "width=" + width + ",height=" + height + ",left=" + pos.left + ",top=" + pos.top);
    }

    _twitterShare(url) {
        this._openPopup(url, 690, 300);
    };

    _facebookShare(url) {
        this._openPopup(url, 540, 400);
    };

    _handleEvents() {


        each(this.triggersBaloon, el => {
            el.addEventListener('click', () => {
                el.classList.toggle('-active')
            })
        })

        each(this.triggers, el => {

            el.addEventListener('click', evt => {
                evt.preventDefault();

                let $this = el;
                let url = $this.getAttribute("href");

                switch (true) {
                    case /facebook\.com/g.test(url):
                        this._facebookShare(url);
                        break;
                    case /twitter\.com/g.test(url):
                        this._twitterShare(url);
                        break;
                    case /pinterest\.com/g.test(url):
                        this._twitterShare(url);
                        break;
                    case /wa\.me/g.test(url):
                        this._facebookShare(url);
                        break;
                    default:
                        return false;

                }
            });
        });
    }

    initClipboard() {
        const _clipboard = new ClipboardJS('[js-clipboard]');
        const _clip = x0('[js-clipboard]');

        if (_clip) {
            _clipboard.on('success', function(e) {
                e.trigger.classList.add('-active');
                setTimeout(() => {
                    e.trigger.classList.remove('-active');
                }, 2000);
            });

            if (!_clip.hasAttribute('data-clipboard-text')) {
                _clip.setAttribute('data-clipboard-text', window.location.href);
            }
        }

    }


    init() {
        this.initClipboard();
        this._handleEvents();
    }

}
