import { each, uniq, pull }  from 'lodash'
import autoComplete from "@tarekraafat/autocomplete.js"
import { x, x0 } from '../_helpers'
import { gsap }  from "gsap/dist/gsap"


export default class Search {

    constructor() {
        this.element = x0('[js-search-autocomplete]')

        if (this.element) {
            this.init()
        }
    }

    interceptEnterKey(e) {
        if (e.keyCode === 13) {
            return true
        }
        return false
    }


    initAutoComplete() {
        this.element = x0('[js-search-autocomplete]')
        const _data = this.element.getAttribute('js-search-autocomplete')

        new autoComplete({
            data: {
                src: JSON.parse(_data),
                keys: ["label"]
            },
            sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
                if (a.match < b.match) return -1;
                if (a.match > b.match) return 1;
                return 0;
            },
            selector: "[js-search-autocomplete]",           // Input field selector              | (Optional)
            observer: true,                      // Input field observer | (Optional)
            threshold: 1,                        // Min. Chars length to start Engine | (Optional)
            searchEngine: "strict",              // Search Engine type/mode           | (Optional)
            maxResults: 5,                         // Max. number of rendered results | (Optional)
            highlight: true,                       // Highlight matching results      | (Optional)
            resultItem: {
                tag: "li",
                class: "autoComplete_result",
                highlight: "autoComplete_highlight",
                selected: "autoComplete_selected"
            },
            events: {
                input: {
                  selection: (event) => {
                    this.element.value = event.detail.selection.value.label;
                    x0('form').submit();
                  },
                },
            },
        });
    }

    init() {
        this.initAutoComplete()

        this.input = x0('form input')
        this.input.addEventListener('keydown', (e) => {
            if (this.interceptEnterKey(e)) {
                if (this.input.value.length > 3) {
                    x0('form').submit()
                }
            }
        })
    }
}
