import {each} from 'lodash'
import {x, x0} from '../_helpers'

export default class ContactsForm {

    constructor() {
        this.elements = x('[js-contacts-form]')
        if (this.elements.length) {
            each(this.elements, el => {
                this.init(el)
            })
        }
    }

    init(el) {
        this.mapSections(el)
        this.setupEventHandlers(el)
    }

    mapSections(el) {
        this.allSections = x('[js-contacts-form-section]', el)

        this.sectionsMap = {}

        each(this.allSections, section => {
            const keys = JSON.parse(section.getAttribute('js-contacts-form-section'))

            each((typeof keys === 'string') ? [keys] : keys, key => {
                if (typeof this.sectionsMap[key] === 'undefined') {
                    this.sectionsMap[key] = []
                }
                this.sectionsMap[key].push(section)
            })
        })
    }

    setupEventHandlers(el) {
        const form = x0('.m-form', el)

        each(x('input, select, textarea', form), formEl => {
            formEl.addEventListener('invalid', () => {
                if (!form.classList.contains('-invalid')) {
                    form.classList.add('-invalid')
                }
            })
        })

        x0('form', form).addEventListener('submit', () => {
            form.classList.remove('-invalid')
        })

        const subject = x0('[js-contacts-form-subject]', el)

        if (subject) {
            subject.addEventListener('change', (e) => {
                form.classList.remove('-invalid')
                this.setCurrentSection(e.target.value)
            })

            if (subject.value.length > 0) {
                this.setCurrentSection(subject.value)
            }
        }
    }

    setCurrentSection(key) {
        each(this.allSections, this.hideSection.bind(this))

        if (typeof this.sectionsMap[key] !== 'undefined') {
            each(this.sectionsMap[key], this.showSection.bind(this))
        }
    }

    hideSection(section) {
        section.classList.add('-hidden')

        if (x('[js-contacts-form-section]', section).length > 0) {
            // not a child section
            return
        }

        each(x('input, select', section), field => {
            field.setAttribute('disabled', '')
        })
    }

    showSection(section) {
        section.classList.remove('-hidden')

        if (x('[js-contacts-form-section]', section).length > 0) {
            // not a child section
            return
        }

        each(x('input, select', section), field => {
            field.removeAttribute('disabled')
        })
    }
}
