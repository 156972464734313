import Swiper    from 'swiper/bundle'
import { each }  from 'lodash'
import { x, x0 } from '../_helpers'


export default class ZoomCarousel {

    constructor() {
        this.element = x0('[js-zoom-carousel]')
        if (this.element) {
            this.init()
        }
    }

    handleEvents() {
        this.initSwiper()
    }

    initSwiper() {
        let swiper = new Swiper(this.element, {
            loop: true,
            slidesPerView: 1,
            speed: 500,
            zoom: {
                maxRatio: Math.sqrt(2), // swiper applies zoom to both the <picture> and the contained <img> element, alas!
                toggle: true,
            },
            shortSwipes: false,
            longSwipes: false,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: this.element.querySelector('[js-rotate-trigger]'),
                prevEl: this.element.querySelector('[js-arrow-prev]'),
            },
        })

        swiper.on('zoomChange', () => {
            x0('body').classList.toggle('product-zoomed')
        })


        var swiperSlide = x('[js-zoom-carousel] .swiper-slide')
        for(var index = 0; index< swiperSlide.length; index++){
            swiperSlide[index].addEventListener('mouseover',function(e){
                swiper.zoom.in();
            })

            swiperSlide[index].addEventListener('mouseout',function(e){
                swiper.zoom.out();
            })
        }
    }

    init() {
        this.handleEvents()
    }

}
