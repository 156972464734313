import {x, x0, reloadWithCacheBusting, captureException} from '../_helpers'
import {each} from "lodash"
import Rating from "./rating"

export default class ReviewForm {
    constructor(context = document, modal = null) {
        this.element = x0('[js-review-form]', context)
        this.modal = modal

        if (this.element) {
            this.init()
        }
    }

    init() {
        new Rating(this.element)

        this.form = x0('form', this.element)
        this.message = x0('[js-review-form-message]', this.element)

        this.setupTextReviews()

        this.handleSubmit()
    }

    setupTextReviews() {
        const inputs = x('[js-rating] input', this.element)
        each(inputs, input => {
            const question = input.closest('[js-review-form-question]')
            if (!question) {
                return
            }

            const textReview = x0('[js-review-form-text-review]', question)
            if (!textReview) {
                return
            }

            const textarea = x0('textarea', textReview)

            input.addEventListener('change', () => {
                if (parseInt(input.value) === 1) {
                    textReview.classList.remove('-hidden')
                    textarea.removeAttribute('disabled')
                } else {
                    textReview.classList.add('-hidden')
                    textarea.disabled = true
                    textarea.value = ''
                }
                this.modal.checkOverflow()
            })
        })
    }

    handleSubmit() {
        this.form.addEventListener('submit', e => {
            e.preventDefault()

            const button = x0('button[type=submit]', this.element)
            const fields = x('[js-rating]', this.element)

            let valid = 0
            each(fields, field => {
                if (parseInt(field.dataset.rating) > 0) {
                    valid++
                }
            })

            if (valid === fields.length) {
                const formData = new FormData(this.form)
                button.disabled = true

                fetch(e.target.action, {
                    method: 'POST',
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: formData
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.points) {
                            const label = data.points === 1 ? 'punto' : 'punti'
                            window.paneangeli.tooltips.push(
                                `Complimenti!<br>Hai guadagnato ${data.points} ${label}.`,
                                'qubi',
                                '', reloadWithCacheBusting
                            )

                            if (!data.message && this.modal) {
                                this.modal.close()
                            }
                        }

                        if (data.message) {
                            this.message.innerHTML = this.message.innerHTML.replace(':message', data.message)

                            this.form.classList.add('-hidden')
                            this.message.classList.remove('-hidden')

                            if (this.modal) {
                                this.modal.modalBoxContent.dataset.reloadWithoutCacheAfterClose = '1'
                                this.setupMessageButton()
                            }
                        }
                    })
                    .catch((err) => {
                        button.removeAttribute('disabled')
                        captureException(err)
                    })
            }
        })
    }

    setupMessageButton() {
        const cancel = x0('[js-modal-close]', this.message)

        cancel.addEventListener('click', () => {
            this.modal.close()
        })
    }
}
