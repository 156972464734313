import { each }          from 'lodash'
import { x, x0 }         from '../_helpers'

export default class CSelect {

    constructor() {
        this.triggers = x('[js-c-select]')
        if (this.triggers.length) {
            this.init()
        }
    }

    handleEvents() {
        each(this.triggers, el => {
            el.querySelector('select').addEventListener('change', (event) => {
                const option = event.target.options[event.target.selectedIndex]
                el.querySelector('span').textContent = option.innerHTML

                if(option.dataset.href) {
                    document.location = option.dataset.href
                }
            })
        });
    }

    init() {
        this.handleEvents()
    }

}
