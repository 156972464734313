import Swiper from 'swiper/bundle'
import {each} from 'lodash'
import {x, x0} from '../_helpers'


export default class MultiCarousel {

    constructor() {
        this.elements = x('[js-m-carousel-item]')
        if (this.elements.length) {
            this.carousels = []
            this.triggers = x('[js-m-carouse-trigger]')
            this.init()
        }
    }

    init() {
        each(this.elements, (el, i) => {
            this.initSwiper(el, i)
        })

        this.handleEvents()
    }

    handleEvents() {
        each(this.triggers, el => {
            el.addEventListener('click', () => {
                x0('[js-m-carouse-trigger].-active').classList.remove('-active')
                el.classList.add('-active')

                each(this.carousels, el => {
                    el.slideTo(0, 500)
                })

                setTimeout(() => {
                    each(x('.m-multi-carousel__item'), el => {
                        el.classList.remove('-active')
                    })
                    x('.m-multi-carousel__item')[el.getAttribute('js-m-carouse-trigger') - 1].classList.add('-active')
                }, 500)
            })
        })
    }

    initSwiper(el, i) {
        this.carousels[i] = new Swiper(el, {
            speed: 500,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            slidesPerView: 'auto',
            freeMode: window.innerWidth <= 768,
            navigation: {
                nextEl: el.querySelector('[js-arrow-next]'),
                prevEl: el.querySelector('[js-arrow-prev]'),
            },
            pagination: {
                el: el.querySelector('[js-carousel-pagination]'),
                type: 'bullets',
                dynamicBullets: true,
                clickable: true
            }
        })

        const slides = el.querySelectorAll('.swiper-slide')

        this.carousels[i].on('slideChange', (swiper) => {
            if (slides[swiper.activeIndex]) {
                const children = slides[swiper.activeIndex].querySelectorAll('[js-track-event]')
                if (children.length) {
                    each(children, child => {
                        child.dispatchEvent(new CustomEvent('slideIn', {
                            detail: swiper
                        }));
                    })
                }
            }
        })
    }
}
