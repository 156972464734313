import { each }  from 'lodash'
import { gsap }  from "gsap/dist/gsap"
import { x, x0 } from '../_helpers'


export default class ChooseRecipe {

    constructor() {
        this.elements = x('[js-choose-recipe]')
        if (this.elements) {
            this.init()
        }
    }

    handleEvents() {
        each(this.elements, el => {
            let triggers = x('[js-choose-recipe-item]', el);

            each(triggers, (trigger) => {
                trigger.addEventListener('click', e => {
                    el.classList.add('-disable')
                    
                    trigger.classList.toggle('-white')
                    trigger.classList.toggle('-primary2')

                    each(triggers, (trigger) => {
                        gsap.from(trigger.nextElementSibling, {
                            textContent: 0,
                            duration: 2,
                            snap: { textContent: 1},
                            stagger: {
                                each: 1.0,
                                onUpdate: function() {
                                  this.targets()[0].innerHTML = `${this.targets()[0].innerHTML}%`
                                },
                            }
                        });
                    })

                })
            })
        })
    }

    init() {
        this.handleEvents()
    }

}