import {each} from 'lodash'
import {x} from '../_helpers'
import {ScrollTrigger} from "gsap/dist/ScrollTrigger"
import {gsap} from "gsap/dist/gsap";


export default class Tracking {

    constructor(triggers = null) {
        this.triggers = triggers !== null ? triggers : x('[js-track-event]')
        if (this.triggers.length) {
            this.init()
        }
    }

    init() {
        this.handleEvents()
    }

    handleEvents() {
        each(this.triggers, (el) => {
            const trackEvent = JSON.parse(el.getAttribute('js-track-event'))

            if (!trackEvent || typeof trackEvent !== 'object') {
                return
            }

            const events = Array.isArray(trackEvent) ? trackEvent : [trackEvent]

            each(events, (event) => {
                const method = `${event.type}Listener`

                if (typeof this[method] !== 'undefined') {
                    this[method](el, event)
                }
            })
        })
    }

    /*loadedListener(el, event) {
        this.trackEvent(event.data)
    }*/

    viewedListener(el, event) {
        gsap.to(el, {
            scrollTrigger: {
                trigger: el,
                start: '+=50% bottom',
                end: 'bottom bottom',
                once: true,
                onEnter: () => {
                    this.trackEvent(event.data)
                },
            }
        })
    }

    clickListener(el, event) {
        el.addEventListener(event.type, () => {
            this.trackEvent(event.data)
        });
    }

    slideListener(el, event) {
        el.addEventListener('slideIn', () => {
            this.trackEvent(event.data)
        });
    }

    scrollListener(el, event) {
        ScrollTrigger.create({
            trigger: el,
            duration: el.offsetHeight,
            start: "top bottom",
            end: "bottom bottom",
            once: 1,
            onEnter: () => {
                this.trackEvent(event.data)
            },
        });
    }

    trackEvent(data) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(data)
    }

}
