import { each }          from 'lodash'
import { x, x0 }         from '../_helpers'
import { gsap }          from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)


export default class Parallax {

    constructor() {
        this.triggers = x('[js-parallax]')
        if (this.triggers) {
            this.init()
        }
    }

    handleEvents() {
        each(this.triggers, (el, i) => {
            gsap.to(el, {
                scrollTrigger: {
                    trigger: el,
                    start: `top bottom`,
                    end: `+=${el.offsetHeight}`,
                    scrub: .5
                },
                y: 0
            })
        })
    }

    init() {
        this.handleEvents()
    }

}