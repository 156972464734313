import { x, x0, isPlaying } from '../_helpers'
import { gsap } from "gsap/dist/gsap"

export default class Landing {

    constructor() {
        if (!document.body.classList.contains('m-landing')) {
            return false;
        }

        this.hereVideosContainer = x0('.m-landing  .m-media-hero__media');
        this.heroVideos = x('.m-landing video');
        this.heroText = x0('.m-landing .m-media-hero__text')
        this.manifestoVideosContainer = x0('.m-landing .m-landing-manifesto-videos');
        this.manifestoVideos = x('.m-landing .m-landing-manifesto-videos video');
        this.tools  = x('.m-landing img[id^="m-landing-manifesto-"]');
        this.imgScintille = x0('.m-landing #m-landing-scintille');
        this.carousel = x0('.m-landing .m-slide-carousel');

        this.init()
    }

    handleScroll() {
        const _self = this;

        if (this.hereVideosContainer && this.heroVideos) {
            gsap.to(this.hereVideosContainer, {
                yPercent: -320,
                opacity: 0,
                ease: "none",
                scrollTrigger: {
                    scrub: 0,
                    onEnter: () => {
                        _self.heroVideos.forEach(v => {
                            if (isPlaying(v)) {
                                v.pause()
                            }
                        });
                    },
                    onLeaveBack: () => {
                        setTimeout(function() {
                            _self.heroVideos.forEach(v => v.play())
                        }, 2000);
                    }
                },
            });
        }


        if (this.heroText) {
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.m-media-hero__text',
                    scrub: .5,
                    start: "top 50%",
                    toggleClass: "active"
                }
            });
        }

        if (this.tools) {
            this.tools.forEach((t) => {
                gsap.to(t, {
                    yPercent: -250,
                    ease: "none",
                    scrollTrigger: {
                        scrub: 2,
                    },
                });
            });
        }

        if (this.manifestoVideosContainer) {
            gsap.timeline({
                scrollTrigger: {
                    trigger: '.m-landing-manifesto-videos',
                    scrub: .5,
                    start: "top 70%",
                    toggleClass: "active",
                    onEnter: function() {
                        _self.manifestoVideos.forEach(v => v.play());
                    },

                    onEnterBack: function() {
                        _self.manifestoVideos.forEach(v => v.play());
                    },

                    onLeave: function() {
                        _self.manifestoVideos.forEach(v => {
                            if (isPlaying(v)) {
                                v.pause()
                            }
                        });
                    },

                    onLeaveBack: function() {
                        _self.manifestoVideos.forEach(v => {
                            if (isPlaying(v)) {
                                v.pause()
                            }
                        });
                    }
                }
            });
        }

        if (this.imgScintille) {
            gsap.timeline({
                scrollTrigger: {
                    start: "top 50%",
                    trigger: '#m-landing-scintille',
                    scrub: 0.25,
                    toggleClass: "active",
                }
            });
        }

        if (this.carousel) {
            gsap.timeline({
                scrollTrigger: {
                    start: "top 60%",
                    trigger: '.m-slide-carousel',
                    scrub: 0.25,
                    toggleClass: "active",
                }
            });
        }
    }

    init() {
        this.handleScroll()
    }
}
