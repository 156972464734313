import {x, x0} from "../_helpers"
import {each} from "lodash"

export default class Rating {

    constructor(context = document) {
        this.elements = x('[js-rating]', context)
        if (this.elements.length) {
            this.init()
        }
    }

    init() {
        each(this.elements, el => {
            const checked = x0('input:checked', el)

            el.dataset.rating = 0

            if (checked) {
                el.dataset.rating = parseInt(checked.value)
                this.highlightUpTo(el, el.dataset.rating)
            }

            if (!el.classList.contains('-disabled')) {
                this.setEventListeners(el)
            }
        })
    }

    setEventListeners(el) {
        const stars = x('.m-form__rating__star', el)
        const inputs = x('input', el)

        each(inputs, input => {
            input.addEventListener('change', () => {
                el.dataset.rating = parseInt(input.value)
                this.highlightUpTo(el, el.dataset.rating)
            })
        })

        each(stars, (star, i) => {
            star.addEventListener('mouseenter', () => {
                this.highlightUpTo(el, i + 1)
            })

            star.addEventListener('mouseleave', () => {
                this.highlightUpTo(el, el.dataset.rating)
            })
        })
    }

    highlightUpTo(el, value) {
        const stars = x('.m-form__rating__star', el)

        each(stars, (star, i) => {
            if (i <= (value - 1)) {
                star.classList.add('-active')
            } else {
                star.classList.remove('-active')
            }
        })
    }
}
