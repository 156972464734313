import {x} from "../_helpers"
import {each} from "lodash"
import {gsap} from "gsap/dist/gsap"
import {ScrollTrigger} from "gsap/dist/ScrollTrigger"

export default class FilterableGrid {
    constructor() {
        this.els = x('[js-filterable-grid]')

        if (this.els.length) {
            this.init()
        }
    }

    init() {
        this.handleEvents()
    }

    handleEvents() {
        each(this.els, el => {
            el._currentTarget = null

            el.addEventListener('filter', e => {
                if (e.detail !== el._currentTarget) {
                    el._currentTarget = e.detail

                    this.filterCards(el)
                }

            })
        })
    }

    filterCards(el) {
        if (!el._currentTarget) {
            this.runTimeline(el, x('.m-grid__item', el))
        } else {
            this.runTimeline(el, x(`.m-grid__item[data-filterable-grid-targets*="${el._currentTarget}"]`, el))
        }
    }

    runTimeline(el, cardsToShow) {
        const duration = 0.5
        const hideClass = 'm-grid__item--hidden'

        const cardsToHide = x(`.m-grid__item:not(.${hideClass})`, el)

        const tl = new gsap.timeline()

        tl.fromTo(
            cardsToHide,
            {opacity: 1},
            {
                duration,
                opacity: 0,
                onComplete: () => {
                    cardsToHide.forEach(card => card.classList.add(hideClass))

                    /*
                    if not all cards have been animated because they haven't entered the viewport yet,
                    they will not be animated correctly once shown by GSAP.
                    Therefore we reset scrolltrigger so it recalculates positions.
                    */
                    ScrollTrigger.refresh()
                }
            }
        )

        tl.fromTo(
            cardsToShow,
            {opacity: 0},
            {
                duration,
                opacity: 1,
                stagger: {each: 0.1},
                onStart: () => {
                    cardsToShow.forEach(card => card.classList.remove(hideClass))
                }
            }
        )
    }
}
