import { x0 } from '../_helpers'
import { gsap } from "gsap/dist/gsap"


export default class Cursor {

    constructor() {
        this.cursor = x0('.m-cursor')
        this.init();
    }


    init() {
        document.addEventListener('mousemove', (e) => {
            gsap.to(this.cursor, {
                duration: .2,
                y: e.clientY+'px',
                x: e.clientX+'px',
                force3D: true
            });

            if (e.target && e.target.hasAttribute && e.target.hasAttribute('js-cursor')) {
                let targetClasses = e.target.getAttribute('js-cursor');
                this.cursor.className = 'm-cursor';
                this.cursor.classList.add(targetClasses);
            } else {
                this.cursor.className = 'm-cursor';
            }
        })
    }
}
