import { delay, each }          from 'lodash'
import { x, x0 }         from '../_helpers'
import { gsap }          from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)


export default class Reveal {

    constructor() {
        this.triggers = x('[js-reveal]')
        if (this.triggers) {
            this.init()
        }
    }

    handleEvents() {
        ScrollTrigger.batch(this.triggers, {
            start: "top bottom",
            onEnter: function(batch) {
                var bdelay = 0;
                if (batch.length === 1) {
                    bdelay = (+batch[0].getAttribute('js-delay') || 0);
                }
                gsap.to(batch, {
                    opacity: 1,
                    rotate: 0,
                    duration: 1,
                    delay: bdelay,
                    y: 0,
                    x: 0,
                    stagger: {
                        each: 0.1
                    },
                    overwrite: true
                })
            }
        })
    }

    init() {
        this.handleEvents()
    }

}