import Swiper            from 'swiper/bundle'
import { each }          from 'lodash'
import { x, x0 }         from '../_helpers'
import { gsap }          from "gsap/dist/gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)


export default class StickyCarousel {

    constructor() {
        this.elements = x('[js-sticky-trigger-card]')
        if (this.elements.length) {
            this.init()
        }
    }

    reset() {
        each(x('[js-sticky-card]'), el => {
            el.classList.remove('-is-active')
        })
    }

    handleEvents() {
        let st = ScrollTrigger.create({
            trigger: "[js-sticky-container]",
            pin: "[js-sticky-element]",
            start: "top top",
            end: "bottom bottom",
        })


        ScrollTrigger.batch(this.elements, {
            toggleActions: "play play play play",
            start: "top 50%",
            onEnter: (batch) => {
                this.reset()
                x0("[js-sticky-card="+batch[0].getAttribute('js-sticky-trigger-card')+"]").classList.add('-is-active')
            },
            onEnterBack: (batch) => {
                this.reset()
                x0("[js-sticky-card="+batch[0].getAttribute('js-sticky-trigger-card')+"]").classList.add('-is-active')
            }
        })
    }

    handleSwiper() {
        this.mobileCarousel = new Swiper(x0('[js-sticky-container] .swiper-container'), {
            speed: 500,
            slidesPerView: 'auto',
            pagination: {
                el: x0('[js-sticky-container] [js-carousel-pagination]'),
                type: 'bullets',
                dynamicBullets: true
            }
        })
    }


    init() {
        this.handleEvents()
        this.handleSwiper()
    }

}
