import { each }  from 'lodash'
import { x, x0 } from '../_helpers'
import { gsap }  from "gsap/dist/gsap"


export default class ProgressBar {

    constructor() {
        this.elements = x('[js-progress-bar-trigger]')
        this.bar = x0('.m-progress-bar')
        if (this.elements && this.bar) {
            this.init()
        }
    }

    show() {
        if (this.bar) {
            this.bar.classList.add('-active')
        }
    }

    hide() {
        if (this.bar) {
            this.bar.classList.remove('-active')
        }
    }

    handleEvents() {
        each(this.elements, el => {
            gsap.to('.m-progress-bar__line', {
                scaleX: 1,
                scrollTrigger: {
                    trigger: el,
                    scrub: 1,
                    duration: el.offsetHeight,
                    start: "top bottom",
                    end: "bottom bottom",

                    onEnter: () => {
                        this.show()
                    },
                    onEnterBack: () => {
                        this.show()
                    },
                    onLeave: () => {
                        this.hide()
                    },
                    onLeaveBack: () => {
                        this.hide()
                    }
                }
            })
        })
    }

    init() {
        this.handleEvents()
    }

}
