import Swiper from 'swiper/bundle'
import {each} from 'lodash'
import {x} from '../_helpers'


export default class Carousel {

    constructor() {
        this.elements = x('[js-carousel]')
        if (this.elements.length) {
            this.init()
        }
    }

    handleEvents() {
        each(this.elements, el => {
            this.initSwiper(el)
        })
    }

    initSwiper(el) {
        let _swiper = new Swiper(el, {
            speed: 500,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            slidesPerView: 'auto',
            simulateTouch: false,
            mousewheel: {
                invert: false,
                forceToAxis: true
            },
            freeMode: false,
            loop: el.hasAttribute('js-carousel-loop'),
            effect: el.getAttribute('js-carousel-effect') === 'fade' ? 'fade' : null,
            fadeEffect: {
                crossFade: true
            },
            navigation: {
                nextEl: el.querySelector('[js-arrow-next]'),
                prevEl: el.querySelector('[js-arrow-prev]'),
            },
            pagination: {
                el: el.querySelector('[js-carousel-pagination]'),
                type: 'bullets',
                dynamicBullets: true,
                clickable: true
            },
        })

        const slides = el.querySelectorAll(el.classList.contains('swiper-container-outer') ? '.swiper-slide-outer' : '.swiper-slide')

        _swiper.on('slideChange', (swiper) => {
            if (slides[swiper.activeIndex]) {
                const children = slides[swiper.activeIndex].querySelectorAll('[js-track-event]')
                if (children.length) {
                    each(children, child => {
                        child.dispatchEvent(new CustomEvent('slideIn', {
                            detail: swiper
                        }));
                    })
                }
            }
        })

        if (x('[js-next-slide]', el).length > 0) {
            each(x('[js-next-slide]', el), el => {
                el.addEventListener('click', () => {
                    _swiper.slideNext();
                })
            })
        }

        if (x('[js-prev-slide]', el).length > 0) {
            each(x('[js-prev-slide]', el), el => {
                el.addEventListener('click', () => {
                    _swiper.slidePrev();
                })
            })
        }
    }

    init() {
        this.handleEvents()
    }

}
