import Topbar from "./_modules/topbar";

require ('./_vendors/form-request-submit-polyfill')

import {each} from 'lodash'
import {isTouch, x, x0} from './_helpers'
import yall from './_vendors/yall.js'
import * as Sentry from "@sentry/browser"

import Menu from './_modules/menu'
import Carousel from './_modules/carousel'
import MultiCarousel from './_modules/multi-carousel'
import StickyCarousel from './_modules/sticky-carousel'
import ZoomCarousel from './_modules/zoom-carousel'
import Reveal from './_modules/reveal'
import Magnet from './_modules/magnet'
import Cursor from './_modules/cursor'
import SplitLines from './_modules/split-lines'
import Parallax from './_modules/parallax'
import ProgressBar from './_modules/progress-bar'
import ScrollTo from './_modules/scroll-to'
import Tool from './_modules/tool'
import Filters from './_modules/filters'
import CSelect from './_modules/c-select'
import CInputFile from './_modules/c-input-file'
import Sidebar from './_modules/sidebar'
import ChooseRecipe from './_modules/choose-recipe'
import Modal from './_modules/modal'
import Favourite from './_modules/favourite'
import TooltipStack from './_modules/tooltip-stack'
import Search from './_modules/search'
import Select from './_modules/select'
import Share from './_modules/share'
import Landing from './_modules/landing'
import Video from './_modules/video'
import Tracking from './_modules/tracking'
import Print from './_modules/print'
import MultiFile from './_modules/multi-file'
import InputList from './_modules/input-list'
import Fadeoutsection from './_modules/fadeoutsection'
import FilterableGrid from './_modules/filterable-grid'
import AddressForm from './_modules/address-form'
import ClickOnce from './_modules/click-once'
import LoadMore from './_modules/load-more'
import Qubi from './_modules/qubi'
import Rating from './_modules/rating'
import FormAttachments from './_modules/form-attachments'
import ContactsForm from './_modules/contacts-form'
import FormInputComplete from './_modules/form-input-autocomplete'
import MapsAutocomplete from './_modules/maps-autocomplete'

const $body = x0('body')

Sentry.init({
    dsn: "https://4035be90077dd582983724d645cb4d64@o1096833.ingest.sentry.io/4506042379403264",
    tracesSampleRate: 0.1,
    environment: $body.dataset.sentryEnv ?? 'local',
    ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        "_AutofillCallbackHandler",
        "NotSupportedError",
        "AbortError",
        "The operation is not supported",
        "The operation was aborted",
        "The request is not allowed by the user agent or the platform in the current context",
        "The play method is not allowed by the user agent or the platform in the current context",
        "The play() request was interrupted",
        "undefined is not an object",
        "e.prevAll(...).eq is not a function"
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        /webkit-masked-url:\/\/hidden\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /anonymous/i,
        /translate\.googleapis\.com/i,
        /translate_http/i
    ],
})

const init = () => {
    /*window.scrollTo(0, 0)
    $body.classList.remove('page-is-loading')
    $body.classList.add('page-is-loaded')*/

    window.paneangeli = {}

    new Carousel()
    new StickyCarousel()
    new ZoomCarousel()
    new Video()
    new Magnet()
    new Cursor()
    new Parallax()
    new Menu()
    new ProgressBar()
    new ScrollTo()
    new Tool()
    new Filters()
    new CSelect()
    new CInputFile()
    new Sidebar()
    new ChooseRecipe()
    new MultiCarousel()
    new Modal()
    new Favourite()
    window.paneangeli.tooltips = new TooltipStack()
    new Search()
    new Share()
    new SplitLines()
    new Landing()
    new Select()
    new Tracking()
    new Print()
    new MultiFile()
    new InputList()
    new Fadeoutsection()
    new FilterableGrid()
    new AddressForm()
    new ClickOnce()
    new LoadMore()
    new Qubi(window.paneangeli.tooltips)
    new Rating()
    new FormAttachments()
    new ContactsForm()
    new FormInputComplete()
    new Topbar()

    setTimeout(() => {
        new Reveal()
    }, 100)

    window.scrollTo(window.scrollX, window.scrollY - 1);
    window.scrollTo(window.scrollX, window.scrollY + 1);

    if (window.pageYOffset > 1) {
        x0('body').classList.add('-disabled-animations')
    }
}

const start = () => {
    !isTouch && document.body.classList.add('no-touch')
    isTouch && document.body.classList.add('is-touch')

    yall({
        threshold: 0,
        noPolyfill: true,
        observeChanges: true,
        events: {
            load: ({target}) => {
                if (target.parentNode) {
                    target.parentNode.classList.add('-loaded')
                }
            },
        },
    });

    init()
}

document.addEventListener('DOMContentLoaded', () => {
    window.scrollTo(0, 0)
    $body.classList.remove('page-is-loading')
    $body.classList.add('page-is-loaded')
})

let initialized = false;
window.onload = () => {
    if (!initialized) {
        start()
        initialized = true;
    }
}

window.initMapsAutocomplete = () => {
    new MapsAutocomplete()
}

let marketingCookie = false;
window.addEventListener('CookiebotOnConsentReady', function (e) {
    marketingCookie = Cookiebot.consent.marketing;
}, false);

window.addEventListener('CookiebotOnAccept', function (e) {
    if (Cookiebot.consent.marketing) {
        setTimeout(() => {
            each(x('.m-embed-video iframe'), el => {
                el.style.display = "block"
            })
        }, 200)
        marketingCookie = true;
    }
}, false);
