import {each} from 'lodash'
import {x} from '../_helpers'
import Selectr from 'mobius1-selectr'

// see https://github.com/Mobius1/Selectr/issues/72
Object.defineProperty(Selectr.prototype, 'mobileDevice', {
    get() {
        return false;
    },
    set() {
    },
    enumerable: true,
    configurable: true
});

export default class Select {

    constructor() {
        this.triggers = x('[js-selectr] select')
        if (this.triggers.length) {
            this.init()
        }
    }


    handleEvents() {
        each(this.triggers, el => {
            
            const selector = new Selectr(el, {
                searchable: false,
                placeholder: null
            })

            selector.on('selectr.select', (option) => {
                if (option.dataset.href) {

                    document.location = option.dataset.href

                } else if (el.hasAttribute('js-filterable-grid-trigger')) {

                    const event = new CustomEvent('filter', {detail: option.dataset.target})
                    el.closest('[js-filterable-grid]').dispatchEvent(event)

                }
            })
        })
    }

    init() {
        this.handleEvents()
    }

}
