import {x} from "../_helpers";
import {each} from "lodash";

export default class Qubi {
    constructor(tooltips) {
        this.tooltips = tooltips

        const tokenMeta = document.querySelector('meta[name="csrf-token"]')
        this.token = tokenMeta ? tokenMeta.content : null

        this.eventTriggers = x('[js-qubi-event]')

        if (this.eventTriggers.length) {
            this.initEventTriggers(this.eventTriggers)
        }
    }

    initEventTriggers(triggers) {
        each(triggers, trigger => {
            trigger.addEventListener('click', () => {
                const url = trigger.getAttribute('js-qubi-event')
                this.triggerEvent(url)
            })
        })
    }

    triggerEvent(url) {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': this.token
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.points) {
                    const label = data.points === 1 ? 'punto' : 'punti'
                    this.tooltips.push(`Complimenti!<br>Hai guadagnato ${data.points} ${label}.`, 'qubi')
                }
            })
    }
}
