import {each} from 'lodash'
import {x, x0, reloadWithCacheBusting, getCookie} from '../_helpers'
import tingle from 'tingle.js'
import Tracking from "./tracking"
import ReviewForm from './review-form'

export default class Topbar {

    constructor(topbar = null) {
        this.topbar = topbar !== null ? topbar : document.querySelector(".m-topbar");
        if (this.topbar) {
            this.init()
        }
    }

    init() {
        let topbar = this.topbar
        let topbarArrow = topbar.querySelector(".m-topbar__icon");
        let mSiteContainer = document.querySelector(".m-site");
        let mMenuContainer = document.querySelector(".m-menu");

        mSiteContainer.classList.add("menu-has-topbar");

        if(!topbar.querySelector("input").checked) {
            mSiteContainer.classList.add("top-bar-closed");
            mMenuContainer.classList.add("top-bar-closed");
        } else {
            mSiteContainer.classList.add("top-bar-opened");
            mMenuContainer.classList.add("top-bar-opened");
        }

        this.resetComponentsDuringScrolling(mSiteContainer, mMenuContainer);
        this.openTopbar(topbarArrow, topbar, mSiteContainer, mMenuContainer);
    }

    openTopbar(topbarArrow, topbar, mSiteContainer, mMenuContainer) {
        topbarArrow.addEventListener('click', () => {

            if(!topbar.querySelector("input").checked) {
                topbar.querySelector("input").checked = true;
                mSiteContainer.classList.add("top-bar-opened");
                mSiteContainer.classList.remove("top-bar-closed");
                mMenuContainer.classList.add("top-bar-opened");
                mMenuContainer.classList.remove("top-bar-closed");
            } else {
                topbar.querySelector("input").checked = false;
                mSiteContainer.classList.add("top-bar-closed");
                mSiteContainer.classList.remove("top-bar-opened");
                mMenuContainer.classList.add("top-bar-closed");
                mMenuContainer.classList.remove("top-bar-opened");
            }

        })
    }

    resetComponentsDuringScrolling(mSiteContainer, mMenuContainer,) {
        document.addEventListener("scroll", (event) => {
            setTimeout(function () {
                if (document.querySelector(".m-header").classList.contains("-hidden")) {
                    mSiteContainer.classList.remove("menu-has-topbar");
                    mMenuContainer.classList.remove("menu-has-topbar");
                    mSiteContainer.classList.add("hidden-topbar");
                    mMenuContainer.classList.add("hidden-topbar");
                } else {
                    mSiteContainer.classList.remove("hidden-topbar");
                    mMenuContainer.classList.remove("hidden-topbar");
                    setTimeout(function () {
                        mSiteContainer.classList.add("menu-has-topbar");
                        mMenuContainer.classList.add("menu-has-topbar");
                    }, 100);
                }

            }, 0);
        });
    }
}
