import {each} from 'lodash'
import {x, x0} from '../_helpers'

export default class FormAttachments {

    constructor() {
        this.elements = x('[js-form-attachments]')
        if (this.elements.length) {
            each(this.elements, this.init.bind(this))
        }
    }

    init(el) {
        this.setupEventHandlers(el)
    }

    setupEventHandlers(el) {
        const dataTransfer = new DataTransfer()

        const input = x0('[js-form-attachments-input]', el)
        const list = x0('[js-form-attachments-list]', el)

        input.addEventListener('change', (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files.item(i)

                if (file.size / 1024 / 1024 > 5) {
                    return alert("Attenzione, la dimensione del file non può superare i 5MB")
                }

                // Make sure `file.name` matches our extensions criteria
                if (!/\.(jpe?g|png|pdf)$/i.test(file.name)) {
                    return alert("Attenzione, formato del file non supportato")
                }

                this.appendLine(file, list, e.target, dataTransfer)

                dataTransfer.items.add(file)
            }

            e.target.files = dataTransfer.files
        })
    }

    appendLine(file, list, input, dataTransfer) {
        const line = document.createElement('li')
        line.innerHTML = file.name

        const remove = document.createElement('span')
        remove.setAttribute('js-form-attachments-remove', file.name)
        line.appendChild(remove)

        list.appendChild(line)

        remove.addEventListener('click', e => {
            e.preventDefault()
            this.removeLine(line, file, input, dataTransfer)
        })

        return line
    }

    removeLine(line, file, input, dataTransfer) {
        for (let i = 0; i < dataTransfer.items.length; i++) {
            if (file.name === dataTransfer.items[i].getAsFile().name) {
                dataTransfer.items.remove(i)
                continue
            }
        }

        input.files = dataTransfer.files

        line.parentElement.removeChild(line)
    }
}
