import {each} from 'lodash'
import autoComplete from "@tarekraafat/autocomplete.js"
import {x, x0} from '../_helpers'


export default class FormInputAutocomplete {
    constructor() {
        this.elements = x('[js-form-input-autocomplete]')

        if (this.elements.length) {
            each(this.elements, this.init.bind(this))
        }
    }

    init(el) {
        const config = JSON.parse(el.getAttribute('js-form-input-autocomplete'))

        const trigger = x0('[js-form-input-autocomplete-trigger]', el)
        const target = x0('[js-form-input-autocomplete-target]', el)

        new autoComplete({
            data: {
                src: config.options,
                keys: ["label"]
            },
            sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
                if (a.match < b.match) return -1;
                if (a.match > b.match) return 1;
                return 0;
            },
            selector: () => trigger,
            observer: true,                      // Input field observer | (Optional)
            threshold: 1,                        // Min. Chars length to start Engine | (Optional)
            searchEngine: "strict",              // Search Engine type/mode           | (Optional)
            maxResults: 5,                         // Max. number of rendered results | (Optional)
            highlight: true,                       // Highlight matching results      | (Optional)
            resultItem: {
                tag: "li",
                class: "autoComplete_result",
                highlight: "autoComplete_highlight",
                selected: "autoComplete_selected"
            },
            events: {
                input: {
                    selection: (event) => {
                        trigger.value = event.detail.selection.value.label;
                        target.value = event.detail.selection.value.value
                    },
                },
            },
        })
    }
}
