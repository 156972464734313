import {each} from 'lodash'
import {x} from '../_helpers'

export default class CInputFile {

    constructor() {
        this.triggers = x('[js-input-file]')
        if (this.triggers.length) {
            this.init()
        }
    }

    handleEvents() {
        each(this.triggers, el => {
            var label    = el.nextElementSibling,
                labelVal = label.innerHTML;

            el.addEventListener('change', function( e ){
                var fileName = '';
                if( this.files && this.files.length > 1 ) {
                    fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                } else {
                    fileName = e.target.value.replace('C:\\fakepath\\', '');
                }

                if( fileName ) {
                    label.querySelector('span').innerHTML = fileName;
                } else {
                    label.innerHTML = labelVal;
                }

                if (this.files.length) {
                    let form_data = new FormData();
                    form_data.append(this.name, this.files[0]);

                    let request = new XMLHttpRequest();

                    request.open('post', this.dataset.uploadUrl);

                    const tokenMeta = document.querySelector('meta[name="csrf-token"]');
                    if (tokenMeta) {
                        request.setRequestHeader('X-CSRF-TOKEN', tokenMeta.content);
                    }

                    request.send(form_data);

                    request.onload = () => {
                        if (request.status >= 200 && request.status < 300) {
                            if (this.dataset.placeholderId) {
                                const response = JSON.parse(request.response)
                                const image = document.getElementById(this.dataset.placeholderId)
                                if (image) {
                                    image.src = response.url
                                }
                            }
                        }
                    }
                }
            });
        })
    }

    init() {
        this.handleEvents()
    }

}
